import { useState, useCallback } from 'react'

const useDropdown = (initialIsOpen = false) => {
  const [isOpen, setDropdownState] = useState(initialIsOpen)
  const toggle = useCallback(() => setDropdownState(!isOpen), [isOpen])
  const open = useCallback(() => setDropdownState(true), [])
  const close = useCallback(() => setDropdownState(false), [])
  return {
    isOpen,
    toggle,
    open,
    close
  }
}

export default useDropdown
