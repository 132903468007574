import * as firebase from 'firebase/app'
import { SubmissionError } from 'redux-form'

export const createUserWithEmailAndPassword = async ({ data: { email, password } = {} }) => {
  return await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(error => {
      throw new SubmissionError({ _error: error.message })
    })
}

export const signInWithEmailAndPassword = async ({ data: { email, password } = {} }) => {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(error => {
      throw new SubmissionError({ _error: error.message })
    })
}

export const signOut = async () => {
  return await firebase.auth().signOut()
}
