import React from 'react'
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress } from 'reactstrap'

import useDropdown from './useDropdown'

const Tasks = ({ itemsCount = 5 }) => {
  const { isOpen, toggle } = useDropdown(false)

  return (
    <Dropdown nav className="d-md-down-none" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav>
        <i className="icon-list"></i>
        <Badge pill color="warning">
          {itemsCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg">
        <DropdownItem header tag="div" className="text-center">
          <strong>You have {itemsCount} pending tasks</strong>
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Upgrade NPM &amp; Bower{' '}
            <span className="float-right">
              <strong>0%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="info" value={0} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            ReactJS Version{' '}
            <span className="float-right">
              <strong>25%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="danger" value={25} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            VueJS Version{' '}
            <span className="float-right">
              <strong>50%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="warning" value={50} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Add new layouts{' '}
            <span className="float-right">
              <strong>75%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="info" value={75} />
        </DropdownItem>
        <DropdownItem>
          <div className="small mb-1">
            Angular 2 Cli Version{' '}
            <span className="float-right">
              <strong>100%</strong>
            </span>
          </div>
          <Progress className="progress-xs" color="success" value={100} />
        </DropdownItem>
        <DropdownItem className="text-center">
          <strong>View all tasks</strong>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Tasks
