import React from 'react'
import { FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'

export default ({
  input,
  type = 'text',
  meta: { valid, invalid, touched, error },
  label,
  LabelProps = {},
  InputProps = {},
  helperText,
  options = [],
  multiple = false,
  ...props
}) => {
  return (
    <FormGroup {...props}>
      {label && (
        <Label for={input.name} {...LabelProps}>
          {label}
        </Label>
      )}
      {type === 'select' ? (
        <Input
          {...input}
          {...InputProps}
          type={type}
          id={input.id || input.name}
          valid={touched && valid}
          invalid={touched && invalid}
          multiple
        >
          {Array.from(options).map(({ value, label, ...option }) => (
            <option key={value} value={value} {...option}>
              {label}
            </option>
          ))}
        </Input>
      ) : (
        <Input
          {...input}
          type={type}
          id={input.id || input.name}
          valid={touched && valid}
          invalid={touched && invalid}
        />
      )}
      {touched && error && <FormFeedback>{error}</FormFeedback>}
      {helperText && <FormText color="muted">{helperText}</FormText>}
    </FormGroup>
  )
}

/*
active: false
asyncValidating: false
autofilled: false
dirty: false
dispatch: ƒ (action)
error: undefined
form: "login"
initial: undefined
invalid: false
pristine: true
submitFailed: false
submitting: false
touched: false
valid: true
visited: false
warning: undefined
*/
