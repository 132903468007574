import React from 'react'

const Footer = () => (
  <React.Fragment>
    <span>
      <a href="https://coreui.io">CoreUI</a> &copy; 2019 creativeLabs.
    </span>
    <span className="ml-auto">
      Powered by <a href="https://coreui.io/react">CoreUI for React</a>
    </span>
  </React.Fragment>
)

export default Footer
