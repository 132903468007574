import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import logo from 'assets/img/brand/logo.svg'
import sygnet from 'assets/img/brand/sygnet.svg'

import { NotificationsDropdown, TasksDropdown, MessagesDropdown, AccountDropdown } from './Dropdown'

const Header = () => (
  <React.Fragment>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand
      full={{ src: logo, width: 89, height: 25, alt: 'CoreUI Logo' }}
      minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
    />
    <AppSidebarToggler className="d-md-down-none" display="lg" />
    <Nav className="d-md-down-none" navbar>
      <NavItem className="px-3">
        <NavLink to="/dashboard" className="nav-link">
          Dashboard
        </NavLink>
      </NavItem>
      <NavItem className="px-3">
        <NavLink to="/users" className="nav-link">
          Users
        </NavLink>
      </NavItem>
      <NavItem className="px-3">
        <NavLink to="#" className="nav-link">
          Settings
        </NavLink>
      </NavItem>
    </Nav>
    <Nav className="ml-auto" navbar>
      <NotificationsDropdown />
      <TasksDropdown />
      <MessagesDropdown />
      <NavItem className="d-md-down-none">
        <NavLink to="#" className="nav-link">
          <i className="icon-location-pin"></i>
        </NavLink>
      </NavItem>
      <AccountDropdown />
    </Nav>
    <AppAsideToggler className="d-md-down-none" />
  </React.Fragment>
)

export default Header
