import React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form as ReduxForm, reduxForm, getFormValues } from 'redux-form'
import _ from 'lodash'

export const onSubmitSuccessCommon = (result, dispatch, ownProps) => {
  console.log('onSubmitSuccess: result', result)
}

export const onSubmitFailCommon = (errors, dispatch, submitError, ownProps) => {
  console.error('onSubmitFail', errors, submitError)
}

const Form = ({ children, handleSubmit, onSubmit, ...props }) => {
  return (
    <React.Fragment>
      <ReduxForm onSubmit={handleSubmit(data => onSubmit({ ...props, params: props.match.params, data }))}>
        {children({ ...props })}
      </ReduxForm>
    </React.Fragment>
  )
}

export default compose(
  withRouter,
  connect((state, ownProps) => ({
    onSubmitSuccess: onSubmitSuccessCommon,
    onSubmitFail: onSubmitFailCommon,
    ...ownProps,
    initialValues: _.omit(ownProps.initialValues, 'id', 'owner', 'createdAt', 'updatedAt'),
    doc: getFormValues(ownProps.form)(state) || ownProps.initialValues
  })),
  reduxForm()
)(Form)
