/** @format */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

import Login from './Login'
import Signup from './Signup'

const Auth = ({ match }) => (
  <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
    <Switch>
      <Route path={`${match.path}/login`} component={Login} />
      <Route path={`${match.path}/signup`} component={Signup} />
    </Switch>
  </Container>
)

export default Auth
