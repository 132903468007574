import React from 'react'
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress } from 'reactstrap'

import useDropdown from './useDropdown'

const Notifications = ({ itemsCount = 5 }) => {
  const { isOpen, toggle } = useDropdown(false)

  return (
    <Dropdown nav className="d-md-down-none" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav>
        <i className="icon-bell"></i>
        <Badge pill color="danger">
          {itemsCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header tag="div" className="text-center">
          <strong>You have {itemsCount} notifications</strong>
        </DropdownItem>
        <DropdownItem>
          <i className="icon-user-follow text-success"></i> New user registered
        </DropdownItem>
        <DropdownItem>
          <i className="icon-user-unfollow text-danger"></i> User deleted
        </DropdownItem>
        <DropdownItem>
          <i className="icon-chart text-info"></i> Sales report is ready
        </DropdownItem>
        <DropdownItem>
          <i className="icon-basket-loaded text-primary"></i> New client
        </DropdownItem>
        <DropdownItem>
          <i className="icon-speedometer text-warning"></i> Server overloaded
        </DropdownItem>
        <DropdownItem header tag="div" className="text-center">
          <strong>Server</strong>
        </DropdownItem>
        <DropdownItem>
          <div className="text-uppercase mb-1">
            <small>
              <b>CPU Usage</b>
            </small>
          </div>
          <Progress className="progress-xs" color="info" value="25" />
          <small className="text-muted">348 Processes. 1/4 Cores.</small>
        </DropdownItem>
        <DropdownItem>
          <div className="text-uppercase mb-1">
            <small>
              <b>Memory Usage</b>
            </small>
          </div>
          <Progress className="progress-xs" color="warning" value={70} />
          <small className="text-muted">11444GB/16384MB</small>
        </DropdownItem>
        <DropdownItem>
          <div className="text-uppercase mb-1">
            <small>
              <b>SSD 1 Usage</b>
            </small>
          </div>
          <Progress className="progress-xs" color="danger" value={90} />
          <small className="text-muted">243GB/256GB</small>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Notifications
