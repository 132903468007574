import { useState } from 'react'

const useTabs = (initialTab = 0) => {
  const [tabActive, setTabActive] = useState(initialTab)
  return {
    tabActive,
    setTabActive
  }
}

export default useTabs
