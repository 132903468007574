/** @format */
import React from 'react'
import { Form, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, email, password } from 'lib/form/validators'

import { createUserWithEmailAndPassword } from 'api/auth'

import { Alert, Card, CardImg, CardBody, Button } from 'reactstrap'

const Signup = () => {
  return (
    <Card className="p-2" style={{ minWidth: 430, maxWidth: 430 }}>
      <CardImg
        top
        style={{ height: 132, width: 323, margin: '0 auto' }}
        src="http://sesomo.gamesmania.io/UK/myRes/Images/Logo-Sesomo.png"
        alt="Card image cap"
      />
      <CardBody>
        <h3 className="text-center">Sesomo Cloud Create User</h3>
        <Form form="login" onSubmit={createUserWithEmailAndPassword}>
          {({ submitFailed, submit, submitting, error }) => (
            <React.Fragment>
              <Field
                name="email"
                type="email"
                component={TextField}
                label="Email Address"
                validate={[required(), email()]}
              />
              <Field
                name="password"
                type="password"
                component={TextField}
                label="Password"
                validate={[required(), password()]}
              />
              {submitFailed && error && <Alert color="danger">{error}</Alert>}
              <Button color="primary" block onClick={submit} disabled={submitting}>
                Signup
              </Button>
            </React.Fragment>
          )}
        </Form>
      </CardBody>
    </Card>
  )
}

export default Signup
