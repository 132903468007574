import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react'
import navigation from 'config/navigation'

import Loading from 'components/Loading'
import Header from './Header'
import Aside from './Aside'
import Footer from './Footer'

const DefaultLayout = ({ routes, ...props }) => (
  <div className="app">
    <AppHeader fixed>
      <Header />
    </AppHeader>
    <div className="app-body">
      <AppSidebar fixed display="lg">
        <AppSidebarHeader />
        <AppSidebarForm />
        <AppSidebarNav navConfig={navigation} {...props} />
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </AppSidebar>
      <main className="main">
        <AppBreadcrumb appRoutes={routes} />
        <Container fluid>
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map((route, idx) => (route.component ? <Route key={idx} {...route} /> : null))}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Suspense>
        </Container>
      </main>
      <AppAside fixed>
        <Aside />
      </AppAside>
    </div>
    <AppFooter>
      <Footer />
    </AppFooter>
  </div>
)

export default DefaultLayout
