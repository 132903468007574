import React from 'react'
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import useDropdown from './useDropdown'

const Messages = ({ itemsCount = 5 }) => {
  const { isOpen, toggle } = useDropdown(false)

  return (
    <Dropdown nav className="d-md-down-none" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav>
        <i className="icon-envelope-letter"></i>
        <Badge pill color="info">
          {itemsCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg">
        <DropdownItem header tag="div">
          <strong>You have {itemsCount} messages</strong>
        </DropdownItem>
        <DropdownItem href="#">
          <div className="message">
            <div className="pt-3 mr-3 float-left">
              <div className="avatar">
                <img src={'assets/img/avatars/7.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                <span className="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small className="text-muted">John Doe</small>
              <small className="text-muted float-right mt-1">Just now</small>
            </div>
            <div className="text-truncate font-weight-bold">
              <span className="fa fa-exclamation text-danger"></span> Important message
            </div>
            <div className="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </DropdownItem>
        <DropdownItem href="#">
          <div className="message">
            <div className="pt-3 mr-3 float-left">
              <div className="avatar">
                <img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                <span className="avatar-status badge-warning"></span>
              </div>
            </div>
            <div>
              <small className="text-muted">Jane Doe</small>
              <small className="text-muted float-right mt-1">5 minutes ago</small>
            </div>
            <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div className="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </DropdownItem>
        <DropdownItem href="#">
          <div className="message">
            <div className="pt-3 mr-3 float-left">
              <div className="avatar">
                <img src={'assets/img/avatars/5.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                <span className="avatar-status badge-danger"></span>
              </div>
            </div>
            <div>
              <small className="text-muted">Janet Doe</small>
              <small className="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div className="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </DropdownItem>
        <DropdownItem href="#">
          <div className="message">
            <div className="pt-3 mr-3 float-left">
              <div className="avatar">
                <img src={'assets/img/avatars/4.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                <span className="avatar-status badge-info"></span>
              </div>
            </div>
            <div>
              <small className="text-muted">Joe Doe</small>
              <small className="text-muted float-right mt-1">4:03 AM</small>
            </div>
            <div className="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div className="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </DropdownItem>
        <DropdownItem href="#" className="text-center">
          <strong>View all messages</strong>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Messages
