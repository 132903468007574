import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { FirebaseAuthConsumer } from '@react-firebase/auth'

import Auth from 'components/Auth'
import { DefaultLayout } from 'layouts'

import './App.scss'

import routes from 'routes'

const App = () => {
  return (
    <FirebaseAuthConsumer>
      {({ isSignedIn, user, providerId }) => {
        console.log(isSignedIn, user, providerId)
        return (
          <Switch>
            <Route
              path={`/auth`}
              render={props =>
                isSignedIn ? <Redirect from={props.location.pathname} to="/dashboard" /> : <Auth {...props} />
              }
            />
            <Route
              path={`/`}
              render={props =>
                isSignedIn ? (
                  <DefaultLayout {...props} routes={routes} />
                ) : (
                  <Redirect
                    from={props.location.pathname}
                    to={{
                      pathname: '/auth/login',
                      state: { referrer: props.location.pathname }
                    }}
                  />
                )
              }
            />
          </Switch>
        )
      }}
    </FirebaseAuthConsumer>
  )
}

export default App
