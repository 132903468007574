import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter as RouterProvider } from 'react-router-dom'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import { FirebaseAuthProvider } from '@react-firebase/auth'
import firebaseConfig from 'config/firebase'

import * as serviceWorker from './serviceWorker'

import createStore from 'store'
import App from 'App'

const store = createStore()

ReactDOM.render(
  <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
    <StoreProvider store={store}>
      <RouterProvider>
        <App />
      </RouterProvider>
    </StoreProvider>
  </FirebaseAuthProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
